"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var User_1 = require("@web/contexts/User");
var react_1 = require("react");
var Header = function (_a) {
    var toggleMenu = _a.toggleMenu;
    var _b = (0, react_1.useContext)(User_1.UserContext), logout = _b.logout, currentAccount = _b.currentAccount;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("header", { className: "header", children: (0, jsx_runtime_1.jsxs)("div", { className: "header__inner", children: [(0, jsx_runtime_1.jsx)("button", { type: "button", className: "icon-menu", onClick: toggleMenu, children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/img/burger.webp", alt: "Burger Menu" }) }), (0, jsx_runtime_1.jsx)("a", { href: "/", className: "header__logo", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/img/logo.webp", alt: "Logo" }) }), currentAccount && ((0, jsx_runtime_1.jsx)("div", { className: "profile", children: (0, jsx_runtime_1.jsxs)("button", { onClick: function () {
                                logout();
                            }, className: "profile__action", children: [(0, jsx_runtime_1.jsx)("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.85355 4.14645C6.65829 3.95118 6.34171 3.95118 6.14645 4.14645L3.14645 7.14645L3.08859 7.21569C2.9536 7.41056 2.97288 7.67999 3.14645 7.85355L6.14645 10.8536L6.21569 10.9114C6.41056 11.0464 6.67999 11.0271 6.85355 10.8536L6.91141 10.7843C7.0464 10.5894 7.02712 10.32 6.85355 10.1464L4.70702 8H13.5L13.692 8.00518C15.5357 8.10487 17 9.63144 17 11.5C17 13.433 15.433 15 13.5 15H8.5L8.41012 15.0081C8.17688 15.0504 8 15.2545 8 15.5C8 15.7761 8.22386 16 8.5 16H13.5L13.7118 15.9951C16.0988 15.8845 18 13.9143 18 11.5C18 9.01472 15.9853 7 13.5 7H4.70702L6.85355 4.85355L6.91141 4.78431C7.0464 4.58944 7.02712 4.32001 6.85355 4.14645Z", fill: "#606B85" }) }), "\u0412\u0438\u0439\u0442\u0438 \u0437 \u0441\u0438\u0441\u0442\u0435\u043C\u0438"] }) }))] }) }) }));
};
exports.default = Header;
