"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDateForTemplate = void 0;
var formatDateForTemplate = function (start_date) {
    var date = new Date(start_date);
    var day = date.getDate().toString().padStart(2, '0');
    var year = date.getFullYear();
    var months = [
        'січня',
        'лютого',
        'березня',
        'квітня',
        'травня',
        'червня',
        'липня',
        'серпня',
        'вересня',
        'жовтня',
        'листопада',
        'грудня'
    ];
    var month = months[date.getMonth()];
    return "\u00AB".concat(day, "\u00BB ").concat(month, " ").concat(year, " \u0440.");
};
exports.formatDateForTemplate = formatDateForTemplate;
