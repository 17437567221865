"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable max-statements */
var react_scheduler_1 = require("@aldabil/react-scheduler");
var material_1 = require("@mui/material");
var trpc_1 = require("@web/lib/trpc");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var locale_1 = require("date-fns/locale");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var Calendar = function () {
    var _a, _b, _c;
    var filters = (0, useFilters_1.useFilters)();
    var searchParams = (0, react_router_dom_1.useSearchParams)()[0];
    var viewParam = searchParams.get('view') || 'week';
    var dateParam = searchParams.get('date');
    var dayParam = searchParams.get('day');
    var calculateInitialDate = function () {
        if (dayParam)
            return new Date(dayParam);
        if (dateParam)
            return new Date(dateParam);
        return new Date();
    };
    var calculateInitialView = function () {
        return dayParam ? 'day' : viewParam;
    };
    var _d = (0, react_1.useState)('default'), mode = _d[0], setMode = _d[1];
    var _e = (0, react_1.useState)(calculateInitialView()), view = _e[0], setView = _e[1];
    var _f = (0, react_1.useState)(calculateInitialDate()), currentDate = _f[0], setCurrentDate = _f[1];
    var calendarRef = (0, react_1.useRef)(null);
    var _g = trpc_1.trpc.reception.receptionList.useQuery({
        userData: (_a = filters.userData) === null || _a === void 0 ? void 0 : _a.toString(),
        dateFrom: (_b = filters.dateAdmissionFrom) === null || _b === void 0 ? void 0 : _b.toString(),
        dateTo: (_c = filters.dateAdmissionTo) === null || _c === void 0 ? void 0 : _c.toString(),
        accountId: filters.accountId,
        serviceId: filters.serviceId,
        durationFrom: filters.durationFrom,
        durationTo: filters.durationTo,
        stateReception: filters.stateReception
    }), receptions = _g.data, isLoading = _g.isLoading;
    var doctors = trpc_1.trpc.account.list.useQuery().data;
    var events = (receptions || []).map(function (reception) { return ({
        event_id: reception.id,
        title: "".concat(reception.user.firstName, " ").concat(reception.user.lastName, " - ").concat(reception.service.name),
        start: new Date(reception.startDate),
        end: new Date(reception.endDate),
        admin_id: reception.accountId
    }); });
    var filteredResources = (function () {
        if (!doctors || !events.length)
            return [];
        return doctors
            .filter(function (doctor) {
            return view === 'day'
                ? events.some(function (event) {
                    return event.admin_id === doctor.id &&
                        new Date(event.start).toDateString() ===
                            currentDate.toDateString();
                })
                : events.some(function (event) { return event.admin_id === doctor.id; });
        })
            .map(function (doctor) { return ({
            admin_id: doctor.id,
            title: "".concat(doctor.firstName, " ").concat(doctor.lastName)
        }); });
    })();
    if (isLoading || !receptions || !doctors)
        return (0, jsx_runtime_1.jsx)("div", { children: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F..." });
    var handleModeChange = function (newMode) {
        var _a, _b;
        setMode(newMode);
        (_b = (_a = calendarRef.current) === null || _a === void 0 ? void 0 : _a.scheduler) === null || _b === void 0 ? void 0 : _b.handleState(newMode, 'resourceViewMode');
    };
    if (dayParam && view !== 'day') {
        setView('day');
        setCurrentDate(new Date(dayParam));
    }
    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", { style: { textAlign: 'center' }, children: [(0, jsx_runtime_1.jsx)("span", { children: "\u0420\u0435\u0436\u0438\u043C \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434\u0443: " }), ['default', 'tabs'].map(function (btnMode) { return ((0, jsx_runtime_1.jsx)(material_1.Button, { color: mode === btnMode ? 'primary' : 'inherit', variant: mode === btnMode ? 'contained' : 'text', size: "small", onClick: function () { return handleModeChange(btnMode); }, children: btnMode === 'default' ? 'Блоки' : 'Вкладки' }, btnMode)); })] }), (0, jsx_runtime_1.jsx)(react_scheduler_1.Scheduler, { ref: calendarRef, events: events, resources: filteredResources, resourceFields: {
                    idField: 'admin_id',
                    textField: 'title',
                    colorField: 'color'
                }, view: view, onViewChange: setView, selectedDate: currentDate, hourFormat: "24", locale: locale_1.uk, translations: {
                    navigation: {
                        month: 'Місяць',
                        week: 'Тиждень',
                        day: 'День',
                        today: 'Сьогодні',
                        agenda: 'Порядок денний'
                    },
                    form: {
                        addTitle: 'Додати подію',
                        editTitle: 'Редагувати подію',
                        confirm: 'Підтвердити',
                        delete: 'Видалити',
                        cancel: 'Скасувати'
                    },
                    event: {
                        title: 'Назва',
                        subtitle: 'Опис',
                        start: 'Початок',
                        end: 'Завершення',
                        allDay: 'Весь день'
                    },
                    moreEvents: 'Ще події',
                    noDataToDisplay: 'Немає даних для відображення',
                    loading: 'Завантаження...'
                } })] }));
};
exports.default = Calendar;
