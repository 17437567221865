"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.priceConvert = void 0;
var priceConvert = function (num) {
    var wordsMap = {
        0: 'нуль',
        1: 'одна',
        2: 'дві',
        3: 'три',
        4: 'чотири',
        5: 'п’ять',
        6: 'шість',
        7: 'сім',
        8: 'вісім',
        9: 'дев’ять',
        10: 'десять',
        11: 'одинадцять',
        12: 'дванадцять',
        13: 'тринадцять',
        14: 'чотирнадцять',
        15: 'п’ятнадцять',
        16: 'шістнадцять',
        17: 'сімнадцять',
        18: 'вісімнадцять',
        19: 'дев’ятнадцять',
        20: 'двадцять',
        30: 'тридцять',
        40: 'сорок',
        50: 'п’ятдесят',
        60: 'шістдесят',
        70: 'сімдесят',
        80: 'вісімдесят',
        90: 'дев’яносто',
        100: 'сто',
        200: 'двісті',
        300: 'триста',
        400: 'чотириста',
        500: 'п’ятсот',
        600: 'шістсот',
        700: 'сімсот',
        800: 'вісімсот',
        900: 'дев’ятсот'
    };
    var getWord = function (number) {
        if (number <= 20)
            return wordsMap[number];
        if (number < 100) {
            var tens = Math.floor(number / 10) * 10;
            var units = number % 10;
            return units ? "".concat(wordsMap[tens], " ").concat(wordsMap[units]) : wordsMap[tens];
        }
        if (number < 1000) {
            var hundreds = Math.floor(number / 100) * 100;
            return "".concat(wordsMap[hundreds], " ").concat(getWord(number % 100)).trim();
        }
        return '';
    };
    var declension = function (number, nominative, genitiveSingular, genitivePlural) {
        var lastDigit = number % 10;
        var lastTwoDigits = number % 100;
        if (lastTwoDigits >= 11 && lastTwoDigits <= 14)
            return genitivePlural;
        if (lastDigit === 1)
            return nominative;
        if (lastDigit >= 2 && lastDigit <= 4)
            return genitiveSingular;
        return genitivePlural;
    };
    var processLargeNumbers = function (number, unitNominative, unitGenitiveSingular, unitGenitivePlural) {
        var units = Math.floor(number);
        if (units === 0)
            return '';
        var unitWord = declension(units, unitNominative, unitGenitiveSingular, unitGenitivePlural);
        return "".concat(getWord(units), " ").concat(unitWord).trim();
    };
    var integerPart = Math.floor(num);
    var decimalPart = Math.round((num - integerPart) * 100);
    var result = '';
    if (integerPart >= 1000000) {
        var millions = Math.floor(integerPart / 1000000);
        result +=
            processLargeNumbers(millions, 'мільйон', 'мільйони', 'мільйонів') + ' ';
        integerPart %= 1000000;
    }
    if (integerPart >= 1000) {
        var thousands = Math.floor(integerPart / 1000);
        result += processLargeNumbers(thousands, 'тисяча', 'тисячі', 'тисяч') + ' ';
        integerPart %= 1000;
    }
    result += getWord(integerPart);
    var integerDeclension = declension(integerPart, 'гривня', 'гривні', 'гривень');
    var decimalText = "".concat(decimalPart, " ").concat(declension(decimalPart, 'копійка', 'копійки', 'копійок'));
    return "".concat(result, " ").concat(integerDeclension, " ").concat(decimalText).trim();
};
exports.priceConvert = priceConvert;
