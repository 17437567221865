"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Active_1 = require("@web/components/Filters/Active");
var Category_1 = require("@web/components/Filters/Category");
var ClinicAddress_1 = require("@web/components/Filters/ClinicAddress");
var Name_1 = require("@web/components/Filters/Name");
var NumberService_1 = require("@web/components/Filters/NumberService");
var Price_1 = require("@web/components/Filters/Price");
var Time_1 = require("@web/components/Filters/Time");
var FiltersServices = function () {
    return ((0, jsx_runtime_1.jsxs)("form", { className: "filter__form", children: [(0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(NumberService_1.NumberService, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Name_1.NameFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Category_1.CategoryFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Price_1.PriceFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(ClinicAddress_1.ClinicAddressFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Active_1.ActiveFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Time_1.TimeFilter, {}) })] }));
};
exports.default = FiltersServices;
