"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shared_1 = require("@space-start/shared");
var trpc_1 = require("@web/lib/trpc");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var useFormData = function (selectedAddressId) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var addresses = trpc_1.trpc.address.getAllOfficeAndAddress.useQuery(undefined, {
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10
    }).data;
    var addressOptions = (addresses === null || addresses === void 0 ? void 0 : addresses.map(function (address) { return ({
        label: address.fullTitle,
        value: address.id
    }); })) || [];
    var cabinetOptions = (0, react_1.useMemo)(function () {
        var _a;
        var selectedAddress = addresses === null || addresses === void 0 ? void 0 : addresses.find(function (address) { return address.id === Number(selectedAddressId); });
        return (((_a = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.offices) === null || _a === void 0 ? void 0 : _a.map(function (office) { return ({
            label: office.title,
            value: office.id
        }); })) || []);
    }, [addresses, selectedAddressId]);
    var categoriesOptions = Object.values(shared_1.CategoriesEnum).map(function (value) { return ({
        value: value,
        label: t("categories.".concat(value))
    }); });
    var currencyOptions = Object.values(shared_1.CurrencyEnum).map(function (value) { return ({
        value: value,
        label: value
    }); });
    var discountOptions = Object.values(shared_1.DiscountEnum).map(function (value) { return ({
        value: value,
        label: t("discount.".concat(value))
    }); });
    return {
        addressOptions: addressOptions,
        cabinetOptions: cabinetOptions,
        categoriesOptions: categoriesOptions,
        currencyOptions: currencyOptions,
        discountOptions: discountOptions
    };
};
exports.default = useFormData;
