"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserData = UserData;
var jsx_runtime_1 = require("react/jsx-runtime");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
function UserData() {
    var _a;
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var filter = (0, useFilters_1.useFilters)();
    function onChange(e) {
        navigateTo({
            pathname: filter.location.pathname,
            newFilters: {
                userData: e.target.value
            }
        });
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "label", children: (0, jsx_runtime_1.jsx)("input", { type: "text", className: "input", value: (_a = filter.userData) !== null && _a !== void 0 ? _a : '', onChange: onChange, placeholder: "\u0406\u043C'\u044F, \u043F\u0440\u0456\u0437\u0432\u0438\u0449\u0435 \u0430\u0431\u043E \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0443" }) }));
}
