"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Account_1 = require("@web/components/Filters/Account");
var DateAdmission_1 = require("@web/components/Filters/DateAdmission");
var ServiceOptiom_1 = require("@web/components/Filters/ServiceOptiom");
var StetaRecords_1 = require("@web/components/Filters/StetaRecords");
var Time_1 = require("@web/components/Filters/Time");
var UserData_1 = require("@web/components/Filters/UserData");
var enum_1 = require("@web/enum");
var routers_1 = require("@web/routers");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
var FiltersRecords = function () {
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var filters = (0, useFilters_1.useFilters)();
    var id = (0, react_router_dom_1.useParams)().id;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "filter__form", children: [(0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(UserData_1.UserData, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(DateAdmission_1.DateAdmission, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Account_1.AccountFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(ServiceOptiom_1.ServiceFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(StetaRecords_1.StateReception, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Time_1.TimeFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)("button", { type: "button", className: "btn btn-primary", onClick: function () {
                        navigateTo({
                            pathname: (0, useNavigateTo_1.generateUrlParams)({
                                id: parseInt(id, 10),
                                activeTab: enum_1.RecordsActiveTabs.CALEDAR,
                                state: enum_1.StateTabs.VIEW,
                                url: routers_1.recordsUrl
                            }),
                            newFilters: filters
                        });
                    }, children: "\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438 \u0432 \u043A\u0430\u043B\u0435\u043D\u0434\u0430\u0440\u0456" }) })] }));
};
exports.default = FiltersRecords;
