"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterExpenses = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ClinicAddress_1 = require("@web/components/Filters/ClinicAddress");
var ExpensesDate_1 = require("@web/components/Filters/ExpensesDate");
var ExpensesType_1 = require("@web/components/Filters/ExpensesType");
var Price_1 = require("@web/components/Filters/Price");
var FilterExpenses = function () {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "filter__form", children: [(0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(ExpensesDate_1.ExpensesDateFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(ExpensesType_1.ExpensesTypeFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Price_1.PriceFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(ClinicAddress_1.ClinicAddressFilter, {}) })] }));
};
exports.FilterExpenses = FilterExpenses;
