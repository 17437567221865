"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDayISO = void 0;
exports.formatMinutesToTime = formatMinutesToTime;
function formatMinutesToTime(minutes) {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;
    if (hours && remainingMinutes) {
        return "".concat(hours, " \u0433\u043E\u0434 ").concat(remainingMinutes, " \u0445\u0432");
    }
    if (hours) {
        return "".concat(hours, " \u0433\u043E\u0434");
    }
    else {
        return "".concat(remainingMinutes, " \u0445\u0432");
    }
}
exports.formatDayISO = 'YYYY-MM-DD';
