"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shared_1 = require("@space-start/shared");
var trpc_1 = require("@web/lib/trpc");
var react_i18next_1 = require("react-i18next");
var useFormData = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var addresses = trpc_1.trpc.address.addressList.useQuery().data;
    var addressesOptions = (addresses === null || addresses === void 0 ? void 0 : addresses.map(function (address) { return ({
        value: address.id,
        label: address.fullTitle
    }); })) || [];
    var expensesTypeOptions = Object.values(shared_1.ExpensesTypeEnum).map(function (value) { return ({
        value: value,
        label: t("".concat("expensesType", ".").concat(value))
    }); });
    return { addressesOptions: addressesOptions, expensesTypeOptions: expensesTypeOptions };
};
exports.default = useFormData;
