"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterTransactions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var DatePaid_1 = require("@web/components/Filters/DatePaid");
var Doctors_1 = require("@web/components/Filters/Doctors");
var Name_1 = require("@web/components/Filters/Name");
var PaymentStatus_1 = require("@web/components/Filters/PaymentStatus");
var Price_1 = require("@web/components/Filters/Price");
var TypePayment_1 = require("@web/components/Filters/TypePayment");
var UserData_1 = require("@web/components/Filters/UserData");
var FilterTransactions = function () {
    return ((0, jsx_runtime_1.jsxs)("form", { className: "filter__form", children: [(0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(UserData_1.UserData, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(DatePaid_1.DatePaidFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Doctors_1.DoctorsFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Name_1.NameFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Price_1.PriceFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(PaymentStatus_1.PaymentStatusFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(TypePayment_1.TypePaymentFilter, {}) })] }));
};
exports.FilterTransactions = FilterTransactions;
