"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateTabs = exports.AnalyticsActiveTabs = exports.DefaultActiveTabs = exports.AccountActiveTabs = exports.AddressActiveTabs = exports.RecordsActiveTabs = exports.UsersActiveTabs = void 0;
var UsersActiveTabs;
(function (UsersActiveTabs) {
    UsersActiveTabs["PROFILE"] = "profile";
    UsersActiveTabs["CONTACT"] = "contact";
    UsersActiveTabs["VISIT"] = "visit";
    UsersActiveTabs["HISTORY"] = "history";
})(UsersActiveTabs || (exports.UsersActiveTabs = UsersActiveTabs = {}));
var RecordsActiveTabs;
(function (RecordsActiveTabs) {
    RecordsActiveTabs["PROFILE"] = "profile";
    RecordsActiveTabs["CALEDAR"] = "calendar";
})(RecordsActiveTabs || (exports.RecordsActiveTabs = RecordsActiveTabs = {}));
var AddressActiveTabs;
(function (AddressActiveTabs) {
    AddressActiveTabs["PROFILE"] = "profile";
    AddressActiveTabs["CONTACT"] = "contact";
    AddressActiveTabs["VISIT"] = "visit";
    AddressActiveTabs["HISTORY"] = "history";
})(AddressActiveTabs || (exports.AddressActiveTabs = AddressActiveTabs = {}));
var AccountActiveTabs;
(function (AccountActiveTabs) {
    AccountActiveTabs["PROFILE"] = "profile";
    AccountActiveTabs["SCHEDULE"] = "schedule";
    AccountActiveTabs["SECRET"] = "secret";
})(AccountActiveTabs || (exports.AccountActiveTabs = AccountActiveTabs = {}));
var DefaultActiveTabs;
(function (DefaultActiveTabs) {
    DefaultActiveTabs["INFO"] = "info";
})(DefaultActiveTabs || (exports.DefaultActiveTabs = DefaultActiveTabs = {}));
var AnalyticsActiveTabs;
(function (AnalyticsActiveTabs) {
    AnalyticsActiveTabs["PROFILE"] = "profile";
})(AnalyticsActiveTabs || (exports.AnalyticsActiveTabs = AnalyticsActiveTabs = {}));
var StateTabs;
(function (StateTabs) {
    StateTabs["VIEW"] = "view";
    StateTabs["EDIT"] = "edit";
    StateTabs["CREATE"] = "create";
})(StateTabs || (exports.StateTabs = StateTabs = {}));
