"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.declensionNames = declensionNames;
function declensionNames(fullName) {
    var endings = {
        lastName: {
            ий: 'ого',
            х: 'ха',
            к: 'ка',
            а: 'и',
            о: 'а',
            е: 'я',
            ій: 'ого',
            й: 'я',
            и: 'ого',
            ь: 'я',
            і: 'я'
        },
        firstName: {
            ий: 'ія',
            ор: 'ора',
            а: 'и',
            я: 'ї',
            е: 'я',
            ій: 'ія',
            їй: 'їя',
            ль: 'ля',
            м: 'ма',
            н: 'на',
            р: 'ра',
            ан: 'ана',
            он: 'она',
            ь: 'я'
        },
        fatherly: {
            ич: 'ича',
            на: 'ни',
            ович: 'овича',
            евич: 'евича',
            ична: 'ични',
            овна: 'овни',
            евна: 'евни'
        }
    };
    var parts = fullName.trim().split(/\s+/);
    var lastName = parts[0], firstName = parts[1], fatherly = parts[2];
    function declineWord(word, rules) {
        for (var _i = 0, _a = Object.entries(rules); _i < _a.length; _i++) {
            var _b = _a[_i], ending = _b[0], replacement = _b[1];
            if (word.endsWith(ending)) {
                return word.slice(0, -ending.length) + replacement;
            }
        }
        return word;
    }
    var Surname = declineWord(lastName, endings.lastName);
    var Name = declineWord(firstName, endings.firstName);
    var Fatherly = declineWord(fatherly, endings.fatherly);
    return "".concat(Surname, " ").concat(Name, " ").concat(Fatherly);
}
