"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Account_1 = require("@web/components/Filters/Account");
var AddressSelect_1 = require("@web/components/Filters/AddressSelect");
var AnalyticsType_1 = require("@web/components/Filters/AnalyticsType");
var DateAdmission_1 = require("@web/components/Filters/DateAdmission");
var ServiceOptiom_1 = require("@web/components/Filters/ServiceOptiom");
var UserData_1 = require("@web/components/Filters/UserData");
var FiltersAnalytics = function () {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "filter__form", children: [(0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(UserData_1.UserData, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(DateAdmission_1.DateAdmission, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(AnalyticsType_1.AnalyticsType, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(AddressSelect_1.AddressSelect, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Account_1.AccountFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(ServiceOptiom_1.ServiceFilter, {}) })] }));
};
exports.default = FiltersAnalytics;
